import { gql } from "@apollo/client";

export const SEE_PROJECT_DETAIL = gql`
  query seeProjectDetail($prj_id: Int) {
    seeProjectDetail(prj_id: $prj_id) {
      scarCreatePermission
      result
      error
      project {
        prj_id
        prj_creatorId
        prj_creatorInfo
        prj_openAvailable
        prj_title
        prj_address
        prj_number
        prj_img
        prj_startDate
        prj_endDate
        prj_endRequestDate
        prj_endRequestUserId
        prj_endRequestUserInfo
        prj_status
      }
      members {
        user_id
        user_name
        user_groupCompany
        user_group
        user_profileImg        
      }
    }
  }
`;

export const SEE_SCAR_MEMBER = gql`
  query seeScarMember($prj_id: Int) {
    seeScarMember(prj_id: $prj_id) {
      result
      error
      prj_member {
        creator
        user_id
        user_name
        user_rank
        user_countryCode
        user_cellphone
        unsignedUser_id
        user_email
        user_group
        user_groupCompany
        user_profileImg
      }
    }
  }
`;

export const SEE_SCAR_LIST = gql`
  query seeScarList(
    $prjId: Int
    $searchTerm: String
    $statusFilter: scarFilter
    $scarOrder: String
  ) {
    seeScarList(
      prj_id: $prjId
      searchTerm: $searchTerm
      statusFilter: $statusFilter
      scarOrder: $scarOrder
    ) {
      result
      error
      scarList {
        scar_id
        scar_createdAt
        scar_endDate
        scar_completeDate
        scar_creatorId
        scar_title
        scar_number
        scar_spot
        scar_riskGrade
        scar_riskcode
        scar_circulatorLink
        scar_fixedDoc
        scar_signNumber
        scar_signStatus
        now_signLine_group
        now_signLine_name
        signLine {
          sl_id
          createdAt
          create_id
          create_group
          create_name
          create_rank
          create_countryCode
          create_cellphone
          create_email
          create_img
          create_status
          create_rejectStatus
          create_rejectContent
          check_id
          check_group
          check_name
          check_rank
          check_img
          check_countryCode
          check_cellphone
          check_email
          check_status
          check_rejectStatus
          check_rejectContent
          action_id
          action_group
          action_name
          action_rank
          action_img
          action_countryCode
          action_cellphone
          action_email
          action_status
          action_rejectStatus
          action_rejectContent
          manager_id
          manager_group
          manager_name
          manager_rank
          manager_img
          manager_countryCode
          manager_cellphone
          manager_email
          manager_status
          manager_rejectStatus
          manager_rejectContent
          actionCheck_id
          actionCheck_group
          actionCheck_name
          actionCheck_rank
          actionCheck_img
          actionCheck_countryCode
          actionCheck_cellphone
          actionCheck_email
          actionCheck_status
          actionCheck_rejectStatus
          actionCheck_rejectContent
          finalSign_id
          finalSign_group
          finalSign_name
          finalSign_rank
          finalSign_img
          finalSign_countryCode
          finalSign_cellphone
          finalSign_email
          finalSign_status
          finalSign_rejectStatus
          finalSign_rejectContent
          circulators {
            circ_id
            circ_cellphone
            circ_email
          }
        }
        scarAction {
          sa_id
          sa_createdAt
          sa_text
          sa_completeDate
          scarActionImg {
            sai_id
            sai_img
          }
        }
        scar_label {
          sLbl_id
          sLbl_name
        }
        img {
          si_id
          si_img
        }
        rejectContent
        history {
          sh_id
          sh_createdAt
          sh_text
          sh_userName
          sh_userImg
          sh_userRank
        }
      }
      scarStatusCount {
        totalCount
        inProgressCount
        actionRequireCount
        confirmRequireCount
        rejectCount
        finalCount
      }
    }
  }
`;
