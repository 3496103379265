import { useState, useEffect, createContext, useCallback } from "react";
import AppRouter from "./Router";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { colors } from "./styles/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SEE_MY_PROFILE_INFO } from "graphql/Profile/query";
import Report from "routes/Report";
import { GlobalLoadingIndicator, client } from "apollo/Client";
import { ApolloProvider } from "@apollo/client";

export const AppContext = createContext(null);
export const ProfileContext = createContext(null);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [language, setLanguage] = useState(
    localStorage.getItem("lang") || "KOR"
  );
  const [myProfile, setMyProfile] = useState(null);
  const [subScriptOn, setSubScriptOn] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [withdrawalModal, setWithdrawalModal] = useState(false);

  const { data: myProfileData, refetch: myProfileRefetch } =
    useQuery(SEE_MY_PROFILE_INFO);

  const handleOpenWithdrawal = useCallback((e) => setWithdrawalModal(true), []);
  const handleCloseWithdrawal = useCallback(
    (e) => setWithdrawalModal(false),
    []
  );

  const previousLoading = () => {
    // tokenValidate();

    try {
      const token = localStorage.getItem("hanmi_scar_token");
      if (token) {
        setIsLoggedIn(true);
        navigate(pathname);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // console.log(myProfileData);
    if (myProfileData?.seeMyProfileInfo?.result) {
      // setLanguage(
      //   myProfileData?.seeMyProfileInfo?.myInfo?.user_language === "kor"
      //     ? "KOR"
      //     : "ENG"
      // );
      setMyProfile(myProfileData.seeMyProfileInfo.myInfo);
    }
  }, [myProfileData]);

  useEffect(previousLoading, []);

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLanguage(localStorage.getItem("lang"));
    }
  }, []);

  // console.log = function no_console() {};
  // console.warn = function no_console() {};
  // console.info = function no_console() {};

  return (
    <ThemeProvider theme={colors}>
      <ApolloProvider client={client}>
        <GlobalStyles />

        <GlobalLoadingIndicator />
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        <AppContext.Provider
          value={{ language, setLanguage, setSubScriptOn, subScriptOn }}
        >
          <ProfileContext.Provider
            value={{
              myProfile,
              myProfileRefetch,
              withdrawalModal,
              handleOpenWithdrawal,
              handleCloseWithdrawal,
            }}
          >
            {pathname.includes("/scar/share/report/") ? (
              <Report />
            ) : (
              <AppRouter
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            )}
          </ProfileContext.Provider>
        </AppContext.Provider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
