import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { AppContext } from "App";
import clockIcon from "assets/icon/clockIcon.svg";
import dangerIcon from "assets/icon/dangerIcon.svg";
import { useNavigate } from "react-router-dom";
import { ProjectDetailContext } from "routes/Main/ProjectDetail/ProjectDetail/ProjectDetailContainer";
import { dateFormat, groupChanger } from "utils/common";
import {
  riskGradeChanger,
  scarSignStatusChanger,
} from "lib/ProjectDetailUtils";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { css } from "styled-components";
import exclamationIcon from "assets/icon/exclamationIcon.svg";

const Container = styled.div`
  width: 468px;
  height: 213px;
  display: grid;
  grid-template-columns: 255px 1fr;
  padding: 26px 30px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: ${({ L, S }) => (L ? 18 : S ? 14 : 16)}px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin-left: ${({ RIGHT }) => RIGHT && "auto"};
  /* display: flex;
  align-items: center; */
  margin: ${({ margin }) => margin};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

const TextRowWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ ABSOLUTE }) =>
    ABSOLUTE &&
    css`
      position: absolute;
      right: 30px;
      bottom: 80px;
    `}
  ${({ ERRORTEXT }) =>
    ERRORTEXT &&
    css`
      position: absolute;
      bottom: 30px;
      right: 30px;
    `}
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  margin-left: auto;
`;

const ProfileImage = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-left: auto;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: ${({ emptyProfile }) => (emptyProfile ? "50%" : "cover")};
  background-position: 50%;
`;

const ProjectImage = styled.img`
  width: 38px;
  height: 38px;
  margin-right: 12px;
  border-radius: 10px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
  border: 1px solid ${colors.grayFontColor};
`;

const Icon = styled.img`
  margin-right: 6px;
`;

const ErrorText = styled.div`
  max-width: 200px;
  color: ${colors.redFontColor};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LastSignTarget = styled.span`
  font-weight: bold;
`;

const ScarInfo = ({ memberList = [] }) => {
  const { language } = useContext(AppContext);
  const navigate = useNavigate();

  const { scarList, project } = useContext(ProjectDetailContext);

  const dotColorChanger = (value) => {
    switch (value) {
      case "impossible": //조치불가
        return colors.redFontColor;

      case "reject":
        return colors.redFontColor;

      case "complete": //완료
        return colors.grayFontColor;

      default:
        return colors.mainColor;
    }
  };

  if (scarList.length === 0) {
    return (
      <div>
        {language === "KOR" && "생성된 스카가 없습니다."}
        {language === "ENG" && "Scar is Empty"}
      </div>
    );
  }

  return (
    <>
      {scarList.map((scar, index) => (
        <Container
          key={index}
          onClick={() => {
            navigate(`/scarDetail/${scar.scar_id}`, {
              state: {
                memberList,
              },
            });
            localStorage.setItem("projectInfo", JSON.stringify(project));
          }}
        >
          <Text L BOLD width={300}>
            {scar?.scar_title}
          </Text>
          <Text>{scar?.scar_number}</Text>
          <ProfileImage
            emptyProfile={scar?.signLine?.create_img === ""}
            src={
              scar?.signLine?.create_img === ""
                ? emptyProfile
                : scar?.signLine?.create_img
            }
          />
          <Text>
            <Icon src={clockIcon} />
            {dateFormat(new Date(Number(scar?.scar_createdAt)), "yyyy.MM.dd")} -
            {dateFormat(new Date(Number(scar?.scar_endDate)), "yyyy.MM.dd")}
          </Text>
          <Text RIGHT>
            {groupChanger(scar?.signLine?.create_group, language)}
          </Text>
          <TextRowWrapper>
            <Text>
              <Icon src={dangerIcon} />
              {language === "KOR" && "위험등급"}
              {language === "ENG" && "Risk Grade"}:
            </Text>
            <Text
              margin="0 0 0 5px"
              BOLD
              color={
                scar?.scar_riskGrade === "A"
                  ? colors.redFontColor
                  : scar?.scar_riskGrade === "B"
                  ? colors.yellowColor
                  : colors.blackColor
              }
            >
              {riskGradeChanger(scar?.scar_riskGrade, language)}
            </Text>
          </TextRowWrapper>
          <div></div>
          <div></div>
          <div />
          <TextRowWrapper ABSOLUTE>
            <Dot bgColor={dotColorChanger(scar?.scar_signStatus)} />
            <Text RIGHT>
              {scarSignStatusChanger(scar?.scar_signStatus, language)} :{" "}
              {scar?.scar_signStatus === "reject" ||
              scar?.scar_signNumber === 7 ? (
                <>
                  {(language === "KOR" && "반려") ||
                    (language === "ENG" && "Reject")}
                </>
              ) : scar?.scar_signNumber === 6 ? (
                <>
                  {(language === "KOR" && "완료") ||
                    (language === "ENG" && "Complete")}
                </>
              ) : scar?.scar_signNumber === 1 ? (
                <>
                  {(language === "KOR" && "발행") ||
                    (language === "ENG" && "Issued")}
                </>
              ) : (
                <>
                  {(language === "KOR" && "진행중") ||
                    (language === "ENG" && "In progress")}
                </>
              )}
            </Text>
          </TextRowWrapper>
          <TextRowWrapper>
            <ProjectImage src={scar?.img[0]?.si_img} />
            <Text color={colors.grayFontColor}>x {scar.img.length}</Text>
          </TextRowWrapper>
          <Text RIGHT>
            {scar?.scar_signNumber} / 6{" "}
            <LastSignTarget>({scar?.signLine.finalSign_name})</LastSignTarget>
          </Text>
          {scar?.rejectContent !== "" && (
            <TextRowWrapper ERRORTEXT>
              <Icon src={exclamationIcon} />
              <ErrorText>{scar?.rejectContent}</ErrorText>
            </TextRowWrapper>
          )}
        </Container>
      ))}
    </>
  );
};

export default ScarInfo;
