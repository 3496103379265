import React, { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { colors } from "../../../../../styles/colors";
import { AppContext } from "App";
import { StatsContext } from "routes/Main/ProjectDetail/Stats/StatsContainer";

const Wrapper = styled.div`
  width: 468px;
  height: 100%;
  margin-top: 26px;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.defaultFontColor};
  padding-bottom: 9px;
`;

const ChartBox = styled.div``;

const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Stats = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  white-space: nowrap;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  color: ${({ fontColor, theme }) =>
    fontColor ? fontColor : theme.defaultFontColor};

  & + & {
    margin-top: 6px;
  }
`;

const RowBox = styled.div`
  display: flex;
  margin-top: 9px;
  background-color: ${colors.whiteColor};
`;

const LabelCircleChart = ({}) => {
  const { language } = useContext(AppContext);

  let COLORS = [
    "#6CE5E8",
    "#41B8D5",
    "#2D8BBA",
    "#2F5F98",
    "#31356E",
    "#5E3967",
    "#895273",
    "#B97286",
    "#E28385",
    "#FE9273",
    "#FFA95A",
    "#FFD04C",
    "#C6DE27",
    "#58CB1E",
    "#00AD60",
    "#069E75",
    "#39907F",
    "#2B6D6D",
    "#256D83",
  ];

  const { byLabelScarCount } = useContext(StatsContext);
  const [data, setData] = useState([]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    if (byLabelScarCount) {
      setData(
        byLabelScarCount.map((data, i) => {
          return {
            name: data.labelName,
            value: data.totalPercent,
            ratio: data.totalPercent,
          };
        })
      );
    }
  }, [byLabelScarCount]);

  return (
    <Wrapper>
      <Header>
        {language === "KOR" && "유형별 SCAR 비율"}
        {language === "ENG" && "SCAR Processing Status"}
      </Header>
      <RowBox>
        <ChartBox>
          {data && (
            <PieChart width={410} height={400}>
              <Pie
                data={data}
                label={renderCustomizedLabel}
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={100}
                paddingAngle={5}
                dataKey="value"
                startAngle={120}
                endAngle={-360}
                legendType="line"
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend align="right" layout="vertical" iconType="circle" />
            </PieChart>
          )}
        </ChartBox>
        {/* <StatsBox>
          {data.map((stats, index) => (
            <Stats key={`${stats.name}-${stats.totalPercent}`}>
              <Text>{stats.name}</Text>
            </Stats>
          ))}
        </StatsBox> */}
      </RowBox>
    </Wrapper>
  );
};

export default memo(LabelCircleChart);
