import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";
import emptyProfile from "assets/icon/emptyProfile.svg";
import { groupChanger } from "utils/common";
import { AppContext } from "App";
import ChangeSignLineModal from "./ChangeSignLineModal";

const ProfileCard = ({
  title,
  profileImg,
  group,
  name,
  rank,
  phoneNumber,
  mail,
  status,
  targetName,
  sl_id,
  NOPATCH,
  memberList = [],
  signLineMode = false,
}) => {
  const { language } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  return (
    <Container>
      {!NOPATCH && (
        <ApprovalCheck status={status}>
          {status === "reject" ? (
            <>
              {language === "KOR" && "반려"}
              {language === "ENG" && "Reject"}
            </>
          ) : status === "auth" ? (
            <>
              {language === "KOR" && "승인"}
              {language === "ENG" && "Approve"}
            </>
          ) : (
            <>
              {language === "KOR" && "미승인"}
              {language === "ENG" && "Unaprv"}
            </>
          )}
        </ApprovalCheck>
      )}
      <ProfileImage src={profileImg} />
      <TextColumnBox>
        <TextRow>
          <Text>{groupChanger(group)}</Text>
          <Text width={170}>
            {name} {rank}
          </Text>
        </TextRow>
        <Text width={230}>{phoneNumber}</Text>
        <Text width={230}>{mail}</Text>
      </TextColumnBox>      
      {isModalOpen ? (
        <ChangeSignLineModal
          handleModal={handleModal}
          memberList={memberList}
          sl_id={sl_id}
          signLine_type={targetName}
        />
      ) : null}
    </Container>
  );
};

export default ProfileCard;

const Container = styled.div`
  width: 371px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  padding: 16px 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  /* display: flex; */
  width: ${({ TITLE }) => TITLE && "100%"};
  margin-bottom: ${({ TITLE }) => TITLE && "10px"};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ProfileImage = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: ${({ src }) => (src === "" ? "30%" : "cover")};
`;

const TextColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  & > ${Text}:last-child {
    margin-top: 4px;
  }
`;

const ApprovalCheck = styled.div`
  width: 70px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 10.3px;
  font-weight: bold;
  border-radius: 16px;
  transform: rotate(15deg);
  ${({ status }) =>
    status === "reject"
      ? css`
          border: 1px solid ${colors.redFontColor};
          color: ${colors.redFontColor};
        `
      : status === "auth"
      ? css`
          border: 1px solid ${colors.mainColor};
          color: ${colors.mainColor};
        `
      : css`
          border: 1px solid ${colors.grayFontColor};
          color: ${colors.grayFontColor};
        `}
`;

const TextRow = styled.div`
  display: flex;
  margin-bottom: 12px;
  & > :first-child {
    color: ${colors.grayFontColor};
  }
  & > :last-child {
    font-weight: bold;
    font-size: 18px;
    margin-left: 12px;
  }
`;

const ChangeButton = styled.button.attrs(() => ({
  type: "button",
}))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 12px;
  background-color: #00c0f3;
  color: #ffffff;
  font-size: 14px;
`;
