import { gql } from "@apollo/client";

export const SEE_VOC_NUMBER_USER = gql`
  query seeVocNumberUser {
    seeVocNumberUser {
      result
      error
      vocNumber
    }
  }
`;

export const SEE_FAQ_LIST_USER = gql`
  query seeFAQListUser {
    seeFAQListUser {
      result
      error
      FAQList {
        faq_id
        faq_createdAt
        faq_question
        faq_answer
      }
    }
  }
`;

export const SEE_NOTICE_LIST_USER = gql`
  query seeNoticeListUser {
    seeNoticeListUser {
      result
      error
      noticeList {
        noti_id
        noti_createdAt
        noti_title
        noti_text
        noti_img
      }
    }
  }
`;

export const SEE_MY_ONE_LIST = gql`
  query seeMyOneList {
    seeMyOneList {
      result
      error
      myOneOnOneInfo {
        one_id
        one_createdAt
        one_question
        one_answerText
        one_answer
      }
    }
  }
`;

export const SEE_TOS = gql`
  query seeTOS {
    seeTOS {
      tokenExpired
      totalLength
      tosList {
        tos_id
        tos_createdAt
        tos_creator
        tos_text
        tos_deleteDate
      }
    }
  }
`;
