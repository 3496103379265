import React, {
  useCallback,
  useState,
  createContext,
  useEffect,
  useContext,
} from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  SEE_PROJECT_DETAIL,
  SEE_SCAR_LIST,
} from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { WITHDRAWAL_PROJECT } from "graphql/Main/ProjectDetail/ProjectDetail/mutation";
import ProjectDetailPresenter from "./ProjectDetailPresenter";
import { statusChager } from "lib/ProjectDetailUtils";
import { Route, Routes, useNavigate } from "react-router-dom";
import Stats from "../Stats";
import CreateScar from "../CreateScar";
import { toast } from "react-toastify";
import { AppContext } from "App";
import CheckList from "../CheckList";
import CheckDetail from "../CheckDetail";
import Check from "../Check";

export const ProjectDetailContext = createContext();

const ProjectDetailContainer = () => {
  const id = Number(localStorage.getItem("prjId"));
  const searchSort = sessionStorage.getItem("sort");
  const { language } = useContext(AppContext);
  const navigate = useNavigate();
  const [naviModal, setNaviModal] = useState(false);
  const [memberEditModal, setMemberEditModal] = useState(false);
  const [prjEditModal, setPrjEditModal] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [project, setProject] = useState(null);
  const [scarList, setScarList] = useState([]);
  const [scarCount, setScarCount] = useState(null);
  const [scarOrder, setScarOrder] = useState("scar_number");
  const [inputs, setInputs] = useState({
    searchTerm: "",
    state: searchSort?.length > 0 ? searchSort : "전체",
  });

  const [withdrawalProject] = useMutation(WITHDRAWAL_PROJECT);

  const { data: prjDetailData, refetch: prjDetailRefetch } = useQuery(
    SEE_PROJECT_DETAIL,
    {
      variables: {
        prj_id: id,
      },
    },
  );

  const { data: scarData, refetch: scarRefetch } = useQuery(SEE_SCAR_LIST, {
    variables: {
      prjId: id,
      searchTerm: inputs.searchTerm,
      statusFilter: statusChager(inputs.state, "KOR"),
      scarOrder,
    },
    // fetchPolicy: "network-only",
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      state: "전체",
    });
  }, [inputs]);

  const handleNaviModal = useCallback(() => {
    setNaviModal(!naviModal);
  }, [naviModal]);

  const handlePrjEditModal = useCallback(() => {
    setPrjEditModal(!prjEditModal);
  }, [prjEditModal]);

  const handleMemberEditModal = useCallback(() => {
    setMemberEditModal(!memberEditModal);
  }, [memberEditModal]);

  const handleScarOrder = useCallback((selectedOrder) => {
    // scar_number, scar_riskGrade, scar_createdAt
    setScarOrder(selectedOrder);
  }, []);

  const handleLeaveProject = useCallback(async () => {
    // console.log(
    //   project?.prj_endRequestDate !== "",
    //   Number(project?.prj_endRequestDate) < new Date().getTime()
    // );
    // if (
    //   project?.prj_endRequestDate !== "" &&
    //   Number(project?.prj_endRequestDate) < new Date().getTime()
    // ) {
    //   alert("종료된 프로젝트입니다.");
    //   return;
    // }
    // if (Number(project.prj_endDate) < new Date().getTime()) {
    //   alert("종료된 프로젝트입니다.");
    //   return;
    // }
    if (!window.confirm("정말 프로젝트를 탈퇴 하시겠습니까?")) return;
    try {
      const { data } = await withdrawalProject({
        variables: {
          prjId: id,
        },
      });
      // console.log(data);
      if (data?.withdrawalProject?.result) {
        toast.success("프로젝트를 탈퇴 했습니다.");
        handleNaviModal();
        navigate("/");
        window.location.reload();
      }
    } catch (e) {
      console.log("여기로");
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    scarRefetch();
  }, [scarOrder, scarRefetch]);

  useEffect(() => {
    if (prjDetailData?.seeProjectDetail?.result) {
      setMemberList(
        (prjDetailData.seeProjectDetail?.members || []).filter(
          ({ user_withdrawal }) => !user_withdrawal,
        ),
      );
      setProject(prjDetailData?.seeProjectDetail?.project);
    }
  }, [prjDetailData]);

  useEffect(() => {
    if (scarData?.seeScarList?.result) {
      setScarList(scarData.seeScarList.scarList);
      setScarCount(scarData.seeScarList.scarStatusCount);
    }
  }, [scarData]);

  return (
    <ProjectDetailContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        naviModal,
        handleNaviModal,
        memberEditModal,
        handleMemberEditModal,
        handleLeaveProject,
        project,
        memberList,
        scarList,
        handlePrjEditModal,
        prjEditModal,
        prjDetailRefetch,
        scarCount,
        scarCreatePermission:
          prjDetailData?.seeProjectDetail?.scarCreatePermission,
        handleScarOrder,
        scarOrder,
      }}
    >
      <Routes>
        <Route
          path="/*"
          element={
            <ProjectDetailPresenter
              language={language}
              memberList={memberList}
            />
          }
        />
        <Route path="/stats" element={<Stats />} />
        <Route path="/createScar" element={<CreateScar />} />
        <Route path="/checkList" element={<CheckList />} />
        <Route path="/checkDetail" element={<CheckDetail />} />
        <Route path="/check" element={<Check />} />
      </Routes>
    </ProjectDetailContext.Provider>
  );
};

export default ProjectDetailContainer;
