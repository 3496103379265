import { gql } from "@apollo/client";

export const SAFEY_ACTION_DOCUMENT_DOWN_ALL = gql`
  mutation safeyActionDocumentDownAll($prjId: Int) {
    safeyActionDocumentDownAll(prj_id: $prjId) {
      result
      zipUrl
    }
  }
`;
