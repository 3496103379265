import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { FiX } from "react-icons/fi";
import { AppContext } from "App";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 574px;
  height: 661px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background-color: ${colors.whiteColor};
  overflow-y: auto;
  /* overflow-x: hidden; */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${colors.scrollColor};
  }
  & > .closeIcon {
    position: absolute;
    right: 40px;
    top: 36px;
    font-size: 24px;
    color: ${colors.grayFontColor};
    cursor: pointer;
  }
`;

const Contents = styled.div`
  word-wrap: break-word;
  margin-top: 36px;
  line-height: 140%;
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

const TosModal = ({ handleModal, tos }) => {
  const { language } = useContext(AppContext);
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Text BOLD>
          {language === "KOR" && "이용약관"}
          {language === "ENG" && "Notice"}
        </Text>
        <FiX className="closeIcon" onClick={() => handleModal()} />
        {tos.length ? (
          <Contents dangerouslySetInnerHTML={{ __html: tos[0].tos_text }} />
        ) : null}
      </ModalContainer>
    </Container>
  );
};

export default TosModal;
