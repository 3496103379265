import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import TosModal from "../feature/ServiceCenter/TosModal";
import { useQuery } from "@apollo/client";
import { SEE_TOS } from "../../graphql/ServiceCenter/query";

const FooterContainer = styled.div`
  width: 100%;
  min-width: 1920px;
  height: 154px;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: ${colors.footerBackgroundColor};
`;

const Text = styled.div`
  color: ${colors.grayFontColor};
  margin-bottom: 6px;
  cursor: ${({ POINTER }) => POINTER && "pointer"};
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowBox = styled.div`
  width: 206px;
  display: flex;
  justify-content: space-between;
  margin-right: 214px;
`;

const Footer = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: tos } = useQuery(SEE_TOS);

  const handleModal = (e) => {
    e?.preventDefault();
    setIsOpenModal(false);
  };

  return (
    <FooterContainer>
      <ColumnBox>
        <Text>(주)리스크제로 TEL | 070-4145-4354 FAX | 02-304-4354</Text>
        <Text>
          본사 | 서울특별시 성동구 연무장길 76 성수AK밸리 707,708호, 510호 지점
          | 울산광역시 중구 학성1길 55, 301호
        </Text>
      </ColumnBox>
      <RowBox>
        <Text POINTER onClick={() => setIsOpenModal(true)}>
          이용약관
        </Text>
        <Text
          POINTER
          onClick={() => window.open("https://agreementshg.riskzero.ai/policy")}
        >
          개인정보보호방침
        </Text>
      </RowBox>
      {isOpenModal ? (
        <TosModal handleModal={handleModal} tos={tos.seeTOS?.tosList} />
      ) : null}
    </FooterContainer>
  );
};

export default Footer;
