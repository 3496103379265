import React, { useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import bellIcon from "assets/icon/bellIcon.svg";
import {
  SCAR_DELETE,
  SAFEY_ACTION_DOCUMENT_DOWN,
} from "graphql/Main/ProjectDetail/ScarDetail/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "App";
import { ScarDetailContext } from "routes/Main/ProjectDetail/ScarDetail/ScarDetailContainer";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 296px;
  height: 437px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.lightgrayBorder};
`;

const Icon = styled.img`
  width: 30px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  font-size: 18px;
  color: ${({ color }) => color};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin: ${({ BOLD }) => !BOLD && "12px 0 32px 0"};
`;

const ScarModal = ({ handleModal, scarId }) => {
  const { language } = useContext(AppContext);
  const { openAvilabel } = useContext(ScarDetailContext);
  const navigate = useNavigate();

  const [scarDelete] = useMutation(SCAR_DELETE);
  const [safeyActionDocumentDown] = useMutation(SAFEY_ACTION_DOCUMENT_DOWN);

  const handleDelete = useCallback(async () => {
    if (
      !window.confirm(
        (language === "KOR" && "스카를 삭제하시겠습니까?") ||
          (language === "ENG" &&
            "SCAR data will be deleted permanently.\n This action cannot be undone!")
      )
    ) {
      return;
    }
    try {
      const { data } = await scarDelete({
        variables: {
          scarId: Number(scarId),
        },
      });
      if (data?.scarDelete?.result) {
        navigate("/projectDetail");
        window.location.reload();
      }
      if (data?.scarDelete?.error !== "") {
        toast.error(data?.scarDelete?.error);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const handleSafeyDocDown = useCallback(async () => {
    if (!scarId) return;
    try {
      const { data } = await safeyActionDocumentDown({
        variables: {
          scarId: Number(scarId),
        },
      });
      if (data?.safeyActionDocumentDown?.result) {
        window.open(data?.safeyActionDocumentDown?.docs);
        toast.success(
          (language === "KOR" && "안전조치시정서를 다운로드하였습니다.") ||
            (language === "ENG" && "Download")
        );
      } else {
        toast.error(
          (language === "KOR" && "안전조치시정서 다운로드에 실패하였습니다.") ||
            (language === "ENG" && "Download Fail")
        );
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <Icon src={bellIcon} />
        <Text BOLD>
          {language === "KOR" && "알림"}
          {language === "ENG" && "Setting"}
        </Text>
        <Text>
          {language === "KOR" && "어떤 작업을 하시겠어요?"}
          {language === "ENG" && "Select the menu"}
        </Text>
        <Button onClick={handleDelete} color={colors.redFontColor}>
          {language === "KOR" && "SCAR 삭제하기"}
          {language === "ENG" && "Delete SCAR"}
        </Button>
        <Button onClick={handleSafeyDocDown}>
          {" "}
          {language === "KOR" && "안전조치시정서 다운"}
          {language === "ENG" && "Download as PDF"}
        </Button>
        <Button
          onClick={() => {
            openAvilabel
              ? window.open(
                  `/scar/share/report/${Number(localStorage.getItem("scarId"))}`
                )
              : toast.error(
                  (language === "KOR" && "비공개 프로젝트입니다.") ||
                    (language === "ENG" && "It's a private project.")
                );
          }}
        >
          {language === "KOR" && "회람자 링크 공유"}
          {language === "ENG" && "Share Link"}
        </Button>
        <Button color={colors.grayFontColor} onClick={handleModal}>
          {language === "KOR" && "닫기"}
          {language === "ENG" && "Close"}
        </Button>
      </ModalContainer>
    </Container>
  );
};

export default ScarModal;
