import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { AppContext } from "App";
import SelectMember from "../CreateScar/SelectMember";
import { ScarDetailContext } from "../../../../../routes/Main/ProjectDetail/ScarDetail/ScarDetailContainer";
import { useMutation } from "@apollo/client";
import { CHANGE_SIGN_LINE } from "../../../../../graphql/Main/ProjectDetail/ProjectDetail/mutation";

const ChangeSignLineModal = ({
  handleModal,
  memberList = [],
  sl_id,
  signLine_type,
}) => {
  const { language } = useContext(AppContext);
  const { scarRefetch } = useContext(ScarDetailContext);
  const [input, setInput] = useState(null);

  const [changeSignLine] = useMutation(CHANGE_SIGN_LINE);

  const onChange = async (e) => {
    e.preventDefault();

    try {
      const { data } = await changeSignLine({
        variables: {
          sl_id,
          user_id: input.id,
          signLine_type,
        },
      });
      if (data?.changeSignLine?.result) {
        handleModal();
        await scarRefetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <Container>
      <BgContainer />
      <ModalContainer>
        <SelectMember
          value={input}
          onChange={(name, op) => {
            setInput(op);
          }}
          fullWidth
          wrapperHeight={300}
          placeholder={
            (language === "KOR" && "선택하기") ||
            (language === "ENG" && "Select")
          }
          options={memberList}
        />
        <ButtonWrap>
          <Button color="danger" onClick={() => handleModal()}>
            {language === "KOR" ? "취소" : "Cancel"}
          </Button>
          <Button onClick={onChange}>
            {language === "KOR" ? "변경" : "Change"}
          </Button>
        </ButtonWrap>
      </ModalContainer>
    </Container>
  );
};

export default ChangeSignLineModal;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${colors.modalDarkBgColor};
`;

const ModalContainer = styled.div`
  width: 296px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  gap: 8px;
`;

const Button = styled.button`
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 12px;
  background-color: ${({ color }) =>
    color === "danger" ? "#EE3335" : "#00c0f3"};
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
`;
