import { gql } from "@apollo/client";

export const SEE_SCAR_TOTAL_COUNT = gql`
  query seeScarTotalCount(
    $prj_id: Int
    $searchStart: String
    $searchEnd: String
  ) {
    seeScarTotalCount(
      prj_id: $prj_id
      searchStart: $searchStart
      searchEnd: $searchEnd
    ) {
      result
      error

      byScarCount {
        total
        complete
        unprocessed
      }
      byLabelScarCount {
        labelName
        createCount
        completeCount
        imcompleteCount
        ratio
        totalPercent
      }
      byStatus {
        totalCount
        completeCount
        completeRatio
        inProgressCount
        inProgressRatio
        delayCount
        delayRatio
      }
    }
  }
`;

export const SEE_CHECK_LIST_STATISTICS = gql`
  query seeCheckListStatistics(
    $prj_id: Int!
    $searchStart: String!
    $searchEnd: String!
  ) {
    seeCheckListStatistics(
      prj_id: $prj_id
      searchStart: $searchStart
      searchEnd: $searchEnd
    ) {
      result
      totalEvaluationInfo {
        totalComplyRate
        avgTotalScore
        avgEvaluationScore
        avgRepeatViolation
      }
      checkListSocreRate {
        totalCheckItemCount
        compliant
        compliantCount
        mainlyCompliant
        mainlyCompliantCount
        partiallyCompliant
        partiallyCompliantCount
        notapplicable
        notapplicableCount
      }
      byMonthStatistics {
        month
        complyRate
      }
      bySafetyCategoryStatistics {
        categoryStatisticsList {
          item_kr
          item_eng
          MA
          CO
          PE
          RE
          SC
        }
        totalMA
        totalCO
        totalPE
        totalRE
        totalSC
      }
    }
  }
`;
