import React, { useState, useCallback, useEffect, useContext } from "react";
import CreateScarPresenter from "./CreateScarPresenter";
import { useNavigate } from "react-router-dom";
import { CREATE_SCAR } from "graphql/Main/ProjectDetail/CreateScar/mutation";
import { SEE_MY_PROFILE_INFO } from "graphql/Profile/query";
import { SEE_SCAR_LABEL_LIST } from "graphql/Main/ProjectDetail/CreateScar/query";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { riskGradeChanger } from "lib/ProjectDetailUtils";
import { SEE_SCAR_MEMBER } from "graphql/Main/ProjectDetail/ProjectDetail/query";
import { AppContext } from "App";
import { getExtension } from "../../../../utils/common";
import { ALLOW_FILE_EXTENSION } from "../../../../utils/constants";

const CreateScarContainer = () => {
  const navigate = useNavigate();
  const { language } = useContext(AppContext);
  const [riskcodeModal, setRiskcodeModal] = useState(false);
  const [myProfile, setMyProfile] = useState(null);
  const [labelList, setLabelList] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [selectLineModal, setSelectLinetModal] = useState(false);
  const [inputs, setInputs] = useState({
    content: "",
    position: "",
    label: "",
    labelList: [],
    grade: "상",
    riskCode: "",
    image: [],
    line: "직접입력하기",
    selectLine: null,
    create: null,
    check: null,
    action: null,
    manager: null,
    actionCheck: null,
    finalSign: null,
    circulators: [],
    save: false,
    name: "",
  });
  const [imageThumbnail, setImageThumbnail] = useState([]);

  const { data: myProfileData } = useQuery(SEE_MY_PROFILE_INFO);
  const { data: labelData } = useQuery(SEE_SCAR_LABEL_LIST, {
    variables: {
      lb_language: (language || "").toLowerCase(),
    },
  });
  const { data: memberData } = useQuery(SEE_SCAR_MEMBER, {
    variables: {
      prj_id: Number(localStorage.getItem("prjId")),
    },
  });

  useEffect(() => {
    switch (language) {
      case "KOR":
        setInputs({ ...inputs, grade: "상", line: "직접입력하기" });
        break;
      case "ENG":
        setInputs({ ...inputs, grade: "High", line: "Select" });
        break;
    }
  }, [language]);

  const [createScar] = useMutation(CREATE_SCAR);

  const handleInput = useCallback(
    (e) => {
      const { name, value } = e.target;

      if (value.substring(0, 1) === " ") return;
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if(special_pattern.test(value) == true){
        toast.error(
          (language === "KOR" && "특수문자 입력은 불가합니다.") ||
            (language === "ENG" && "Please Remove Special Character"),
        );
        return false; 
      }
      
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const handleSelect = useCallback(
    (name, value) => {
      setInputs({ ...inputs, [name]: value });
      if (value === "불러오기" || value === "Load") {
        setSelectLinetModal(true);
      }
    },
    [inputs]
  );

  const selectLabel = useCallback(
    (_, value) => {
      if (inputs.labelList?.includes(value)) {
        toast.error(
          (language === "KOR" && "이미 선택한 라벨입니다.") ||
            (language === "ENG" && "The label has already been selected.")
        );
        return;
      }
      setInputs({
        ...inputs,
        labelList: [...inputs.labelList, value],
      });
    },
    [inputs]
  );

  const deleteLabel = useCallback(
    (label) => {
      setInputs({
        ...inputs,
        labelList: inputs.labelList.filter((lb) => lb !== label),
      });
    },
    [inputs]
  );

  const deleteCirculator = useCallback(
    (value) => {
      setInputs({
        ...inputs,
        circulators: inputs.circulators.filter(
          (circulator) => circulator !== value
        ),
      });
    },
    [inputs]
  );

  const onChangeFile = useCallback(
    (e) => {
      let files = e.target.files;

      if (files.length < 1) return;
      if (inputs.image.length + files.length > 5) {
        toast.error(
          (language === "KOR" && "최대 5장의 사진을 업로드할 수 있습니다.") ||
            (language === "ENG" && "Up to five photos can be uploaded.")
        );
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size / 1024 / 1024 > 10) {
          alert(
            (language === "Kor" &&
              "이미지 파일은 10MB 이하로 업로드해주세요.") ||
              (language === "Eng" && "Please upload the image file under 10MB")
          );
          return;
        }

        let ext = getExtension(files[i].name);

        if (!(ALLOW_FILE_EXTENSION.indexOf(ext) > -1) || ext === "") {
          alert(
            (language === "Kor" && "이미지 파일만 업로드 가능합니다.") ||
              (language === "Eng" && "Only image files can be uploaded")
          );
          return;
        }
      }

      setInputs({
        // 뮤테이션 보낼 데이터
        ...inputs,
        image: [...inputs.image, ...files],
      });

      //여기부턴 썸네일
      let thumbnailImgList = [...imageThumbnail];
      for (let i = 0; i < files.length; i++) {
        thumbnailImgList.push(URL.createObjectURL(files[i]));
      }
      setImageThumbnail(thumbnailImgList);
    },
    [inputs, imageThumbnail]
  );

  const deleteImageFile = useCallback(
    (index) => {
      imageThumbnail.splice(index, 1);
      // Array.prototype.splice(inputs.image, index, 1);
      setImageThumbnail(imageThumbnail);
      setInputs({
        ...inputs,
        image: inputs.image.filter((img) => inputs.image[index] !== img),
      });
    },
    [inputs, imageThumbnail]
  );

  const handleCreate = useCallback(async () => {
    if (inputs.content.trim() === "") {
      toast.error(
        (language === "KOR" && "위반사항을 입력해주세요.") ||
          (language === "ENG" && "Please enter a violation.")
      );
      return;
    }
    if (inputs.labelList.length === 0) {
      toast.error(
        (language === "KOR" && "라벨을 선택해주세요.") ||
          (language === "ENG" && "Please select a label.")
      );
      return;
    }
    if (inputs.grade.trim() === "") {
      toast.error(
        (language === "KOR" && "위험등급을 선택해주세요.") ||
          (language === "ENG" && "Please select a risk rating.")
      );
      return;
    }

    if (inputs.save && inputs.name.trim() === "") {
      toast.error(
        (language === "KOR" && "결제라인 이름을 입력해주세요.") ||
          (language === "ENG" && "Please enter a approval process name.")
      );
      return;
    }

    if (inputs.image.length === 0) {
      toast.error(
        (language === "KOR" && "이미지를 업로드해주세요.") ||
          (language === "ENG" && "Please upload the image.")
      );
      return;
    }
    if (
      !inputs.create ||
      !inputs.check ||
      !inputs.action ||
      !inputs.manager ||
      !inputs.actionCheck ||
      !inputs.finalSign
    ) {
      toast.error(
        (language === "KOR" && "결재 라인을 모두 선택해주세요.") ||
          (language === "ENG" && "Please select all the payment lines.")
      );
      return;
    }
    if (
      window.confirm(
        (language === "KOR" && "SCAR를 생성하시겠습니까?") ||
          (language === "ENG" && "Create a SCAR?")
      )
    ) {
      try {
        let variables;
        if (inputs.image.length === 0) {
          variables = {
            prjId: Number(localStorage.getItem("prjId")),
            scarTitle: inputs.content,
            scarSpot: inputs.position,
            scarRiskGrade: riskGradeChanger(inputs.grade, language),
            scarRiskcode: inputs.riskCode,
            scarLabel: inputs.labelList,
            scarImgSelect: false,
            signLine: {
              name: inputs.name,
              create: inputs.create,
              check: inputs.check,
              action: inputs.action,
              manager: inputs.manager,
              actionCheck: inputs.actionCheck,
              finalSign: inputs.finalSign,
              circulators: inputs.circulators,
            },
            saveSignLine: inputs.save,
          };
        } else {
          variables = {
            prjId: Number(localStorage.getItem("prjId")),
            scarTitle: inputs.content,
            scarSpot: inputs.position,
            scarRiskGrade: riskGradeChanger(inputs.grade, language),
            scarRiskcode: inputs.riskCode,
            scarLabel: inputs.labelList,
            scarImgSelect: true,
            scarImgs: inputs.image,
            signLine: {
              name: inputs.name,
              create: inputs.create,
              check: inputs.check,
              action: inputs.action,
              manager: inputs.manager,
              actionCheck: inputs.actionCheck,
              finalSign: inputs.finalSign,
              circulators: inputs.circulators,
            },
            saveSignLine: inputs.save,
          };
        }

        const { data } = await createScar({
          variables: variables,
        });
        if (data?.createScar?.result) {
          window.alert(
            (language === "KOR" && "SCAR를 생성했습니다.") ||
              (language === "ENG" && "SCAR has been created.")
          );
          navigate("/projectDetail");
          window.location.reload();
        }
        if (data?.createScar?.error !== "") {
          toast.error(data?.createScar?.error);
        }
      } catch (e) {
        toast.error(e.message);
        console.info(e);
      }
    }
  }, [inputs]);

  useEffect(() => {
    if (myProfileData?.seeMyProfileInfo?.result) {
      setMyProfile(myProfileData?.seeMyProfileInfo?.myInfo);
    }
  }, [myProfileData]);

  useEffect(() => {
    if (myProfile) {
      setInputs({
        ...inputs,
        create: {
          id: myProfile.user_id,
          group: myProfile.user_group,
          name: myProfile.user_name,
          rank: myProfile.user_rank,
          countryCode: myProfile.user_countryCode,
          cellphone: myProfile.user_cellphone,
          email: myProfile.user_email,
          img: myProfile.user_profileImg,
        },
      });
    }
  }, [myProfile]);

  useEffect(() => {
    if (labelData?.seeScarLabelList?.result) {
      setLabelList(
        labelData.seeScarLabelList.labelList.map((label) => label.lb_name)
      );
    }
  }, [labelData]);

  useEffect(() => {
    if (memberData?.seeScarMember?.result) {
      setMemberList(
        memberData.seeScarMember?.prj_member.filter(
          (member) => member.user_id !== 0
        )
      );
    }
  }, [memberData]);

  return (
    <CreateScarPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleInput={handleInput}
      handleSelect={handleSelect}
      handleCreate={handleCreate}
      myProfile={myProfile}
      labelList={labelList}
      selectLabel={selectLabel}
      deleteLabel={deleteLabel}
      onChangeFile={onChangeFile}
      imageThumbnail={imageThumbnail}
      deleteImageFile={deleteImageFile}
      memberList={memberList}
      deleteCirculator={deleteCirculator}
      riskcodeModal={riskcodeModal}
      setRiskcodeModal={setRiskcodeModal}
      selectLineModal={selectLineModal}
      setSelectLinetModal={setSelectLinetModal}
      language={language}
    />
  );
};

export default CreateScarContainer;
